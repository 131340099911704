var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _c(_vm.component, {
          tag: "component",
          attrs: {
            popupParam: _vm.popupParam,
            patrol: _vm.patrol,
            attachInfo: _vm.attachInfo,
            imprTabInfo: _vm.imprTabInfo,
            isOld: _vm.isOld,
            disabled: _vm.disabled,
          },
          on: {
            "update:popupParam": function ($event) {
              _vm.popupParam = $event
            },
            "update:popup-param": function ($event) {
              _vm.popupParam = $event
            },
            "update:patrol": function ($event) {
              _vm.patrol = $event
            },
            "update:attachInfo": function ($event) {
              _vm.attachInfo = $event
            },
            "update:attach-info": function ($event) {
              _vm.attachInfo = $event
            },
            "update:imprTabInfo": function ($event) {
              _vm.imprTabInfo = $event
            },
            "update:impr-tab-info": function ($event) {
              _vm.imprTabInfo = $event
            },
            getDetail: _vm.getDetail,
            getItems: _vm.getItems,
            closePopup: _vm.closePopup,
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }