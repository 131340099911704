<template>
  <div>
    <template>
      <component
        :is="component"
        :popupParam.sync="popupParam"
        :patrol.sync="patrol"
        :attachInfo.sync="attachInfo"
        :imprTabInfo.sync="imprTabInfo"
        :isOld="isOld"
        :disabled="disabled"
        @getDetail="getDetail"
        @getItems="getItems"
        @closePopup="closePopup"
      />
    </template>
    <!-- <c-tab
      ref="patrolTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :patrol.sync="patrol"
          :attachInfo.sync="attachInfo"
          :imprTabInfo.sync="imprTabInfo"
          :isOld="isOld"
          :disabled="disabled"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab> -->
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrolDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        patrolTypeCd: null,
        plantCd: null,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      patrol: {
        saiPatrolId: '',
        plantCd: '',
        deptCd: '',
        vendorCd: '',
        sopMapId: '',
        patrolTypeCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolCompleteFlag: 'N',
        sopConstructionId: '',
        ramRiskAssessmentPlanId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
        imprs: [],
        deleteImprs: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PATROL_RESULT',
        taskKey: '',
      },
      tab: 'info',
      editable: true,
      detailUrl: '',
      resultItemUrl: '',
      component: null, // 콤포넌트
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let items = [{ name: 'info', icon: 'edit', label: 'LBL00001610', component: () => import(`${'./patrolInfo.vue'}`), key: uid() }];
      // if (this.isOld) {
      //   items.push({ name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) })
      // }
      return items;
    },
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    imprTabInfo() {
      return {
        key: this.popupParam.saiPatrolId, // 
        ibmTaskTypeCd: 'ITT0000065',
        ibmTaskUnderTypeCd: 'ITTU000105',
        requestContents: this.patrol.patrolName,
        disabled: this.disabled,
        isOld: this.isOld,
        isTable: false,
        isGridItem: false,
        gridItem: {
          listUrl: '',
          param: null,
          title: '점검항목별 개선목록',
          merge: [],
          data: this.$_.filter(this.patrol.results, (result) => {
            return result.ibmTooltip
          }),
          columns: [
            {
              name: 'patrolItemName',
              field: 'patrolItemName',
              // 점검항목
              label: 'LBL00001619',
              align: 'left',
              style: 'width:400px',
              sortable: false,
            },
            {
              name: 'patrolResultName',
              field: 'patrolResultName',
              // 점검결과
              label: 'LBL00001620',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'patrolContents',
              field: 'patrolContents',
              // 비고
              label: 'LBLREMARK',
              align: 'left',
              style: 'width:230px',
              sortable: false,
            },
          ],
          research: {},
        },
        gridOther: {
          // 항목외 개선 목록
          title: 'LBL0010147',
        },
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.patrol.get.url;
      this.resultItemUrl = selectConfig.sai.patrol.resultItem.list.url;
      this.component = () => import(`${'./patrolInfo.vue'}`)
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiPatrolId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.patrol, _result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.saiPatrolId)
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.patrol.patrolTypeCd = this.popupParam.patrolTypeCd;
        this.patrol.patrolDate = this.$comm.getToday();
        this.patrol.sopMapId = this.$store.getters.user.userId;
        // this.patrol.patrolName = this.patrol.patrolDate + ' 순회점검'
        this.getItems(this.$store.getters.user.plantCd, this.$store.getters.user.deptCd)
      }
    },
    getItems(_plantCd, _targetDeptCd) {
      this.$http.url = this.resultItemUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: _plantCd,
        patrolTypeCd: this.popupParam.patrolTypeCd,
        useFlag: 'Y',
        targetDeptCd: this.popupParam.patrolTypeCd == 'PTC0000001' ? '' : _targetDeptCd,
      };
      this.$http.request((_result) => {
        this.patrol.results = [];
        this.$_.forEach(_result.data, _item => {
          this.patrol.results.push({
            saiPatrolId: '',
            saiPatrolResultId: uid(),
            saiPatrolItemId:_item.saiPatrolResultItemId,
            patrolItemName: _item.patrolItemName,
            patrolResultCd: 'PRCC000001',
            patrolContents: '',
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C',
          }) 
        })
      },);
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>